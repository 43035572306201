import React from "react"
import {Link,graphql} from "gatsby"

import Layout from "../components/layout"
import Pagination from "../components/pagination"
    
import Seo from "../components/seo"

const BlogListHtml = ({pageContext,data,location}) => {
    const {current,page} = pageContext
    
    const posts = data.allMarkdownRemark.nodes
    
    return(
        <div>
            <Layout>
                <Seo
                    pagetitle="BLOG"
                    pagedesc="MAZINのニュースページ"
                    pagepath={location.pathname}
                /> 
                <div className="container-fluid blogs">
                  <ul className="breadcrumb">
                    <li><Link to={`/`}>TOP</Link></li>
                    <li>BLOG</li>
                  </ul>{/*.breadcrumb*/}
                  <h1>BLOG</h1>
                  <div className="row">
                    {/*<div className="col-12 col-md-5">*/}
                    <div className="col-12">
                        <div className="blog-link">
                        {posts.map(post => {
                            return(
                                <div className="blog-wrap">
                                    <Link key={post.fields.slug} to={`/blog${post.fields.slug}`}>
                                      <img src={post.frontmatter.thumbnail} alt={post.frontmatter.title} className="blog-thumbnail" />
                                      <div>
                                        <div className="blog-date">{post.frontmatter.date}</div>
                                        <div className="blog-title">{post.frontmatter.title}</div>
                                      </div>
                                    </Link>
                                </div>
                            );
                        })}
                        </div>{/*.blog-link*/}
                    </div>
                    <div className="col-12">
                        <Pagination num={page} current={current} />
                    </div>
                  </div>{/*.row*/}
                </div>{/*.container-fluid*/}
            </Layout>
       </div>
    )
}

export default BlogListHtml

export const pageQuery = graphql`
    query list($skip: Int!,$limit: Int!){
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            skip: $skip
            limit: $limit
        ){
            totalCount
            nodes{
                frontmatter{
                  title
                  date
                  thumbnail
                }
                fields {
                  slug
                }
            }
        }
    }
`
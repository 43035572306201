import { Link } from "gatsby"
import React from "react"

const Prev = ({current}) => {    
    if (current === 1) {
		return (``)
	} else if (current === 2) {
		return (
            <Link to={`/blog/`} className="prev">
                <div className="button-left bg-img" />
            </Link>
		)
	} else {
		return (
            <Link to={`/blog/page${current - 1}/`} className="prev">
                <div className="button-left bg-img" />
            </Link>
		)
	}
}

const Next = ({num,current}) => {
	if (current === num) {
		return (``)
	} else {
		return (
			current === ''
                ? <Link to={`/blog/page2/`} className="next">
                    <div className="button-right bg-img" />
                  </Link>
                : <Link to={`/blog/page${current + 1}/`} className="next">
                    <div className="button-right bg-img" />
                  </Link>
		)
	}
}

const PageNumbers = ({num,current}) => {
    //{(() => {
        const Pages = []
        for(let i=0; i<num; i++){
            let PageNumber = i+1
            if(i===0){
                if(PageNumber === current){
                    Pages.push(
                        <Link to={`/blog/`} className="page-numbers current">
                            {PageNumber}
                        </Link>
                    )
                }else{
                    Pages.push(
                        <Link to={`/blog/`} className="page-numbers">
                            {PageNumber}
                        </Link>
                    )
                }
            }else{
                if(PageNumber === current){
                    Pages.push(
                        <Link to={`/blog/page${PageNumber}`} className="page-numbers current">
                            {PageNumber}
                        </Link>
                    )
                }else{
                    Pages.push(
                        <Link to={`/blog/page${PageNumber}`} className="page-numbers">
                            {PageNumber}
                        </Link>
                    )
                }
            }
        }
        return Pages
}

const Pagination = ({num,current}) => {
    return(
        <div className="nav-links">
            <Prev current={current} />
            <PageNumbers num={num} current={current} />
            <Next num={num} current={current} />
        </div>
    )
}

export default Pagination